










import { Component, Vue } from "vue-property-decorator";
import CurvesWrapper from "@/components/knockoutWrappers/CurvesWrapper.vue";
import FeatureNotAvailable from "@/components/FeatureNotAvailable.vue";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";

const Vessels = getModule(VesselsModule, store);

@Component({
  components: {
    CurvesWrapper,
    FeatureNotAvailable,
  },
})
export default class CharterParty extends Vue {
  vessel = Vessels.currentVessel;

  get featureEnabled(): boolean {
    if (!this.vessel) return false;

    return this.vessel.features.some(feature => feature.name === "CharterParty");
  }
}
